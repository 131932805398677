import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import './login.css';
import { FaUser, FaLock, FaEnvelope } from "react-icons/fa";
import axios from "axios";

const Login = () => {
    // State untuk registrasi
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validation, setValidation] = useState("");
    const [action, setAction] = useState(''); 

    const navigate = useNavigate();

    // Fungsi untuk meng-handle registrasi
    const registerHandler = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', name); 
        formData.append('email', email); 
        formData.append('password', password); 
    
        try {
            await axios.post('http://127.0.0.1:8000/api/registered', formData);
            alert("Registrasi berhasil!"); 
            navigate('/'); 
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.errors 
                    ? Object.values(error.response.data.errors).flat().join(", ") 
                    : error.response.data;
                alert(`Registrasi gagal: ${errorMessage}`); 
                setValidation(errorMessage);
            }
        }
    };

    // Fungsi untuk meng-handle login
    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://127.0.0.1:8000/api/login', {
                email: email, 
                password: password,
            });

            localStorage.setItem('token', response.data.token);

            alert("Login berhasil!"); 
            navigate('/dashboard');
        } catch (error) {
            alert("Login gagal! Periksa email dan password Anda."); 
            setValidation("Login gagal! Periksa email dan password Anda.");
        }
    }

    useEffect(() => {
        document.body.classList.add('login-page');
        return () => {
            document.body.classList.remove('login-page');
        };
    }, []);

    // Fungsi untuk menampilkan form registrasi
    const registerLink = () => {
        setAction(' active');
    }

    // Fungsi untuk menampilkan form login
    const loginLink = () => {
        setAction('');
    }

    return (
        <div className={`wrapper${action}`}>
            <div className="form-box login">
                <form onSubmit={handleLogin}> {/* Panggil fungsi handleLogin di sini */}
                    <h1>Login</h1>
                    <div className="input-box">
                        <input 
                            type="text" 
                            placeholder="Username" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                        <FaUser className="icon" />
                    </div>
                    <div className="input-box">
                        <input 
                            type="password" 
                            placeholder="Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                             
                        />
                        <FaLock className="icon" />
                    </div>
                    <div className="remember-forgot">
                        <label>
                            <input type="checkbox" />Remember me
                        </label>
                        <a href="#">Forgot Password?</a>
                    </div>

                    <button type="submit">Sign In</button>
                    <div className="register-link">
                        <p>Don't have an account? <a href="#" onClick={registerLink}>Register</a></p>
                    </div>
                </form>
            </div>

            <div className="form-box register">
                <form onSubmit={registerHandler}> {/* Panggil fungsi registerHandler di sini */}
                    <h1>Registration</h1>
                    <div className="input-box">
                        <input 
                            type="text" 
                            placeholder="Username" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                        />
                        <FaUser className="icon" />
                    </div>
                    <div className="input-box">
                        <input 
                            type="email" 
                            placeholder="Email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                        <FaEnvelope className="icon" />
                    </div>
                    <div className="input-box">
                        <input 
                            type="password" 
                            placeholder="Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                        <FaLock className="icon" />
                    </div>
                    <div className="remember-forgot">
                        <label>
                            <input type="checkbox" />I agree to the terms & conditions
                        </label>
                    </div>

                    <button type="submit">Sign Up</button>
                    <div className="register-link">
                        <p>Already have an account? <a href="#" onClick={loginLink}>Login</a></p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
