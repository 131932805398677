import React from "react";

const Card = ({ icon, title, value }) => {
    return (
        <div className="bg-white p-4 rounded-lg shadow-md flex items-center dark:bg-gray-800 dark:text-white">
            <div className="text-3xl mr-4">{icon}</div>
            <div>
                <h2 className="text-xl font-semibold">{title}</h2>
                <h2 className="text-lg">{value}</h2>
            </div>
        </div>
    );
};

export default Card;
