import React from "react";
import Sidebar from "../shared/sidebar";
import Navbar from "../shared/navbar";
import Card from "../shared/card";
import { FaCog, FaShoppingCart, FaUser } from "react-icons/fa";


const Dashboard = () => {
    return (
        <div className="flex">
            <Sidebar />
            <div className="grow ml-16 md:ml-64 h-full lg:h-screen bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white">
                <Navbar />
                <div className="p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                    <Card icon={<FaShoppingCart />} title="Orders" value="140" />
                    <Card icon={<FaUser />} title="Users" value="5" />
                    <Card icon={<FaCog />} title="Settings" value="140" />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
