import React from "react";
import { FaTachometerAlt, FaUsers, FaCog } from "react-icons/fa";

const Sidebar = () => {
    return (
        <div className="bg-gray-100 text-gray-900 h-screen px-4 fixed w-16 md:w-64 border-r border-gray-300 dark:border-gray-600 dark:bg-gray-900 dark:text-white">
            <div className="flex items-center mt-4">
                <img 
                    src="./logo192.png" // Ganti dengan link foto profil Anda
                    alt="Profile"
                    className="w-12 h-12 rounded-full mr-2" // Ukuran foto dan margin kanan
                />
                <h1 className="text-2xl font-bold hidden md:block italic">React JS</h1>
            </div>
            <ul className="flex flex-col mt-5 text-xl">
                <li className="flex items-center py-3 px-2 space-x-4 hover:rounded hover:bg-blue-600 hover:text-white">
                    <FaTachometerAlt />
                    <span className="hidden md:inline">Dashboard</span>
                </li>
                <li className="flex items-center py-3 px-2 space-x-4 hover:rounded hover:bg-blue-600 hover:text-white">
                    <FaUsers />
                    <span className="hidden md:inline">Users</span>
                </li>
                <li className="flex items-center py-3 px-2 space-x-4 hover:rounded hover:bg-blue-600 hover:text-white">
                    <FaCog />
                    <span className="hidden md:inline">Setting</span>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
